<template>
  <div class="nvr-filters pt-4">
    <div class="grid-header">Filters</div>

    <div class="grid-surface grid-filters pa-2">
      <slot name="actions"></slot>
      <!-- GLOBAL SEARCH -->
      <EGlobalSearch
        :dark="$vuetify.theme.dark"
        :items="searchInputItems"
        :is-fixed-position="false"
        :is-hidden="false"
        class="ma-2"
        :deep="false"
        @search-results="onGlobalSearchResultsChange"
        @reset="onGLobalSearchResultsReset"
      />

      <!-- CAMERA CREATION DATE -->
      <v-menu
        v-model="isCreationDatePickerOpened"
        :close-on-content-click="false"
        max-width="290"
      >
        <template #activator="{ on, attrs }">
          <v-text-field
            v-model="creationDateRangeDisplay"
            label="Camera Creation Date"
            placeholder="Camera Creation Date"
            persistent-placeholder
            readonly
            clearable
            dense
            outlined
            hide-details
            :menu-props="{ maxHeight: 'none' }"
            class="color-select ma-2 white"
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="creationDateRange"
          range
          @change="onCreationDateRangeChange"
        />
      </v-menu>

      <!-- CAMERA MODEL -->
      <v-select
        v-model="selectedCameraModel"
        outlined
        dense
        hide-details
        label="Camera Model"
        :items="cameraModels"
        item-text="name"
        item-value="value"
        :menu-props="{ maxHeight: 'none' }"
        class="color-select ma-2 white"
        @change="(s) => (selectedCameraModel = s)"
      />

      <!-- CAMERA FEATURE FLAG -->
      <v-combobox
        v-model="selectedFeatureFlag"
        :items="featureFlags"
        label="Camera Feature Flag"
        class="ma-2 white"
        item-text="name"
        item-value="name"
        multiple
        dense
        outlined
        chips
        small-chips
        :filter="customFilter"
        :menu-props="{
          closeOnClick: true,
          persistent: false,
        }"
        @keydown="handleKeydown($event, 'featureFlags')"
      />

      <!-- CAMERA STATUS -->
      <v-combobox
        v-model="selectedCameraStatus"
        :items="cameraStatuses"
        label="Camera Status"
        class="ma-2 white"
        item-text="name"
        item-value="name"
        multiple
        dense
        outlined
        chips
        small-chips
        :filter="customFilter"
        :menu-props="{
          closeOnClick: true,
          persistent: false,
        }"
        @keydown="handleKeydown($event, 'cameraStatuses')"
      />
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import { CameraFeatureFlags, CameraStatuses } from "@/components/constants.js"
import CameraUtils from "@/mixins/cameraUtils"

import {
  AdminCamera,
  CameraStatus,
  CameraFeatureFlag,
  CameraModel,
  GlobalSearchResult,
} from "@evercam/shared/types"

export default Vue.extend({
  name: "ExNvrFiltersPanel",
  mixins: [CameraUtils],
  props: {
    cameras: {
      type: Array as PropType<AdminCamera[]>,
      required: true,
    },
  },
  data() {
    return {
      globalSearchResults: [] as GlobalSearchResult[],
      searchResults: [] as AdminCamera[],
      isSearchActive: false,
      selectedCameraModel: "",
      selectedCameraStatus: [{ name: "Online", value: CameraStatus.Online }],
      selectedFeatureFlag: [],
      isCreationDatePickerOpened: false,
      creationDateRange: [],
    }
  },
  computed: {
    creationDateRangeDisplay: {
      get() {
        return this.creationDateRange?.join(" - ")
      },
      set() {
        this.creationDateRange = []
      },
    },
    cameraModels() {
      const cameraModels = Object.values(CameraModel).map((value) => ({
        value,
        name: value,
      }))

      return [{ name: "All", value: "" }, ...cameraModels]
    },
    cameraStatuses() {
      return [...CameraStatuses]
    },
    featureFlags(): { name: string; value: CameraFeatureFlag }[] {
      return CameraFeatureFlags
    },
    searchInputItems(): Record<any, any>[] {
      return this.cameras.map((c) => [
        c.name,
        c.projectExid,
        c.exid,
        c.projectName,
        c.createdAt,
        c.cameraModel,
        c.status,
        c.userFullname,
      ])
    },
    filteredCameras() {
      const searchFilteredCameras = this.getSearchFilteredCameras()

      return searchFilteredCameras.filter(
        (camera) =>
          this.matchesCameraModel(camera) &&
          this.matchesCameraStatus(camera) &&
          this.matchesFeatureFlags(camera) &&
          this.matchesDateRange(camera)
      )
    },
  },
  watch: {
    filteredCameras: {
      immediate: true,
      handler(v) {
        this.$emit("change", v)
      },
    },
  },
  methods: {
    onGlobalSearchResultsChange(results: GlobalSearchResult[]) {
      this.isSearchActive = true
      this.globalSearchResults = results
    },
    onGLobalSearchResultsReset() {
      this.isSearchActive = false
    },
    onCreationDateRangeChange() {
      this.isCreationDatePickerOpened = false
      if ((this.creationDateRange || [])?.length < 2) {
        return
      }

      if (
        this.$moment(this.creationDateRange[0]).isAfter(
          this.$moment(this.creationDateRange[1])
        )
      ) {
        this.creationDateRange = [
          this.creationDateRange[1],
          this.creationDateRange[0],
        ]
      }
    },
    handleKeydown(event, itemType) {
      if (event.key === "Tab" || event.key === "Enter") {
        this.handleTabAndEnterClick(event, itemType)
      }
    },
    customFilter(item, queryText) {
      const query = queryText.toLowerCase()

      return item.name.toLowerCase().includes(query)
    },
    handleTabAndEnterClick(event, itemType) {
      const inputValue = event.target.value.toLowerCase()

      const itemMapping = {
        cameraStatuses: {
          list: this.cameraStatuses,
          selectedList: this.selectedCameraStatus,
        },
        featureFlags: {
          list: this.featureFlags,
          selectedList: this.selectedFeatureFlag,
        },
      }

      const { list, selectedList } = itemMapping[itemType] || {}

      if (list && selectedList) {
        const matchedItem = list.find(
          (item) => item.name.toLowerCase() === inputValue
        )

        if (matchedItem && !selectedList.includes(matchedItem)) {
          selectedList.push(matchedItem)
        }
      }
    },
    getSearchFilteredCameras() {
      if (!this.isSearchActive) {
        return this.cameras
      }

      return this.globalSearchResults.map((match) => this.cameras[match.index])
    },

    getValidFeatureFlags() {
      return this.selectedFeatureFlag
        .filter((flag) => typeof flag === "object" && flag !== null)
        .map((flag) => flag.value)
    },

    matchesFeatureFlags(camera) {
      const featureFlags = this.getValidFeatureFlags()

      return (
        !featureFlags?.length ||
        featureFlags?.some((flag) => camera?.featureFlags?.includes(flag))
      )
    },

    matchesDateRange(camera) {
      if (!this.creationDateRange.length) {
        return true
      }

      const cameraDate = this.$moment(camera.createdAt)

      return (
        cameraDate.isSameOrAfter(this.$moment(this.creationDateRange[0])) &&
        cameraDate.isSameOrBefore(this.$moment(this.creationDateRange[1]))
      )
    },

    getValidCameraStatuses() {
      return this.selectedCameraStatus
        .filter((status) => typeof status === "object" && status !== null)
        .map((status) => status.value)
    },

    matchesCameraStatus(camera) {
      const statusValues = this.getValidCameraStatuses()

      return !statusValues.length || statusValues.includes(camera.status)
    },

    matchesCameraModel(camera) {
      return (
        !this.selectedCameraModel ||
        camera.cameraModel === this.selectedCameraModel
      )
    },
  },
})
</script>

<style>
.nvr-filters .v-text-field__details {
  display: none;
}
.nvr-filters .v-input--dense > .v-input__control > .v-input__slot {
  margin-bottom: 0;
}
</style>
